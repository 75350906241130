import { useState, useEffect, useCallback } from 'react';
import { Season } from '../components/SeasonSelector';
import { getNfts } from '../api/getNfts';
import { EVENT_TYPE } from '../constants/eventType';
import {
    extractSeasonFromNfts,
    extractSeasonFromClubPlayers,
} from '../lib/extractSeason';
import SugorokuMasterManager from '../lib/sugorokuMasterManager';
import Nft from '../types/nftType';
import { getAccountSugorokuProgress } from '../api/getAccountSugorokuProgress';
import type AccountSugorokuProgress from '../types/accountSugorokuType';

type UseSugorokuRewardSeasonsReturn = {
    seasons: Season[];
    selectedSeason: number;
    setSelectedSeason: (seasonId: number) => void;
    nfts: Nft[];
    loadingState: 'loading' | 'success' | 'error';
    hasError: boolean;
    updateSelectedSeasonForTab: (isPlayerListActive: boolean) => void;
};

/**
 * すごろくリワードリストで使用するシーズン情報を管理するフック
 * NFTとプレイヤー情報の両方からシーズン情報を取得し、マージして提供する
 */
export const useSugorokuRewardSeasons = (
    initialTab: 'player' | 'nft' = 'player'
): UseSugorokuRewardSeasonsReturn => {
    const [playerSeasons, setPlayerSeasons] = useState<Season[]>([]);
    const [nftSeasons, setNftSeasons] = useState<Season[]>([]);
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [selectedSeason, setSelectedSeason] = useState<number>(0);
    const [nfts, setNfts] = useState<Nft[]>([]);
    const [progress, setProgress] = useState<AccountSugorokuProgress | null>(
        null
    );
    const [loadingState, setLoadingState] = useState<
        'loading' | 'success' | 'error'
    >('loading');
    const [hasError, setHasError] = useState(false);

    // 選手タブ用の最適なシーズンを取得（タブ切り替え用）
    const getDefaultPlayerSeason = useCallback(
        (sortedSeasons: Season[]) => {
            if (!progress) return sortedSeasons[0]?.id;

            // アンロック済みの選手を特定
            const players = SugorokuMasterManager.getClubPlayers();
            const unlockedPlayers = players.filter((player) =>
                progress.items.some(
                    (item) => item.club_player_code === player.code
                )
            );

            // アンロック済み選手のシーズンIDを取得（降順で最初のもの）
            const unlockedSeasonIds = unlockedPlayers.map(
                (player) => player.season.id
            );
            const defaultSeason = sortedSeasons.find((season) =>
                unlockedSeasonIds.includes(season.id)
            );

            return defaultSeason?.id ?? sortedSeasons[0]?.id;
        },
        [progress]
    );

    // NFTタブ用の最適なシーズンを取得（タブ切り替え用）
    const getDefaultNftSeason = useCallback(
        (sortedSeasons: Season[]) => {
            if (!nfts.length) return sortedSeasons[0]?.id;

            const nftSeasonIds = nfts.map((nft) => nft.event.season.id);
            const defaultSeason = sortedSeasons.find((season) =>
                nftSeasonIds.includes(season.id)
            );

            return defaultSeason?.id ?? sortedSeasons[0]?.id;
        },
        [nfts]
    );

    // タブ切り替え時のシーズン更新
    const updateSelectedSeasonForTab = useCallback(
        (isPlayerListActive: boolean) => {
            const targetSeasons = isPlayerListActive
                ? playerSeasons
                : nftSeasons;
            if (targetSeasons.length === 0) return;

            // 現在選択中のシーズンが新しいタブでも有効な場合はそれを維持
            const currentSeasonExists = targetSeasons.some(
                (season) => season.id === selectedSeason
            );
            if (currentSeasonExists) {
                return; // 現在のシーズン選択を維持
            }

            // 現在のシーズンが新しいタブで無効な場合のみデフォルトシーズンを設定
            const newSeasonId = isPlayerListActive
                ? getDefaultPlayerSeason(targetSeasons)
                : getDefaultNftSeason(targetSeasons);

            if (newSeasonId) {
                setSelectedSeason(newSeasonId);
            }
        },
        [
            playerSeasons,
            nftSeasons,
            getDefaultPlayerSeason,
            getDefaultNftSeason,
            selectedSeason,
        ]
    );

    useEffect(() => {
        const fetchSugorokuRewardData = async () => {
            try {
                setLoadingState('loading');
                const [nftResponse, progressResponse] = await Promise.all([
                    getNfts(EVENT_TYPE.SUGOROKU),
                    getAccountSugorokuProgress(),
                ]);

                setNfts(nftResponse);
                setProgress(progressResponse);

                // NFTとプレイヤーのシーズンを取得
                const extractedNftSeasons = extractSeasonFromNfts(nftResponse);
                const players = SugorokuMasterManager.getClubPlayers();
                const extractedPlayerSeasons =
                    extractSeasonFromClubPlayers(players);

                // NFTのシーズンをソート
                const sortedNftSeasons = extractedNftSeasons
                    .reduce((unique, season) => {
                        const exists = unique.some((s) => s.id === season.id);
                        if (!exists) {
                            unique.push(season);
                        }
                        return unique;
                    }, [] as Season[])
                    .sort((a, b) => b.id - a.id);
                setNftSeasons(sortedNftSeasons);

                // プレイヤーのシーズンをソート
                const sortedPlayerSeasons = extractedPlayerSeasons
                    .reduce((unique, season) => {
                        const exists = unique.some((s) => s.id === season.id);
                        if (!exists) {
                            unique.push(season);
                        }
                        return unique;
                    }, [] as Season[])
                    .sort((a, b) => b.id - a.id);
                setPlayerSeasons(sortedPlayerSeasons);

                // 表示用にマージしたシーズンを作成
                const mergedSeasons = [
                    ...extractedNftSeasons,
                    ...extractedPlayerSeasons,
                ]
                    .reduce((unique, season) => {
                        const exists = unique.some((s) => s.id === season.id);
                        if (!exists) {
                            unique.push(season);
                        }
                        return unique;
                    }, [] as Season[])
                    .sort((a, b) => b.id - a.id);
                setSeasons(mergedSeasons);

                // タブに応じた初期選択シーズンを設定
                const targetSeasons =
                    initialTab === 'player'
                        ? sortedPlayerSeasons
                        : sortedNftSeasons;

                const initialSeasonId = targetSeasons[0]?.id;

                if (initialSeasonId) {
                    setSelectedSeason(initialSeasonId);
                }

                setLoadingState('success');
            } catch (error) {
                console.error('Failed to fetch sugoroku reward data:', error);
                setLoadingState('error');
                setHasError(true);
            }
        };

        fetchSugorokuRewardData();
    }, [initialTab]);

    return {
        seasons, // マージされたシーズンを返す
        selectedSeason,
        setSelectedSeason,
        nfts,
        loadingState,
        hasError,
        updateSelectedSeasonForTab,
    };
};
