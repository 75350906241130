import React from 'react';
import styled from 'styled-components';
import { LoadingSpinnerOverlay } from '../Spinner';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { TabSelector } from '../components/TabSelector';
import { isPWAEnabled } from '../lib/isPWAEnabled';
import { isMobile } from 'react-device-detect';
import { SugorokuErrorDialog } from '../components/SugorokuErrorDialog';
import PlayerList from '../components/PlayerList';
import {
    SugorokuNFTCard,
    SugorokuNFTCardInfo,
} from '../components/SugorokuNFTCard';
import { useNavigate } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { SugorokuNftDetailContent } from '../components/SugorokuNftDetailContent';
import { SeasonSelector } from '../components/SeasonSelector';
import { extractSeasonFromNfts } from '../lib/extractSeason';
import { useSugorokuRewardSeasons } from '../hooks/useSeasons';
import { useEffect } from 'react';
import { useSugorokuReward } from '../hooks/useSugorokuReward';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h2`
    margin: 40px 0 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const Content = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    padding: 0 20px 20px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${() => (isPWAEnabled() && isMobile ? '20px 20px' : '30px 20px')};
    place-items: center center;

    @media (width < 375px) {
        grid-template-columns: 1fr;
    }
`;

const EmptyMessage = styled.p`
    max-width: 400px;
    margin-top: 20px;
    margin-bottom: 300px;
    font-size: 14px;
    text-align: center;
`;

const TitleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const ArrowContainer = styled.div`
    max-width: 400px;
    padding: 0 20px;
    margin: 0 auto;
`;

const ArrowText = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;

const SugorokuRewardList: React.FC = () => {
    const { isPlayerListActive, setIsPlayerListActive } = useSugorokuReward();
    const {
        seasons,
        selectedSeason,
        setSelectedSeason,
        nfts,
        loadingState,
        hasError,
        updateSelectedSeasonForTab,
    } = useSugorokuRewardSeasons('player');
    const { showPlayerDetailScreen, selectedPlayerCode } = useSugorokuReward();
    const navigate = useNavigate();

    useEffect(() => {
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_ATTENDANCE_HISTORY_PAGE);
    }, []);

    const handleArrowClick = () => {
        navigate(PATH_MAPPING.SUGOROKU);
    };

    const handleTabChange = (isPlayerList: boolean) => {
        setIsPlayerListActive(isPlayerList);
        updateSelectedSeasonForTab(isPlayerList);
    };

    const filteredNfts = nfts.filter((nft) => {
        const nftSeason = extractSeasonFromNfts([nft])[0];
        return nftSeason?.id === selectedSeason;
    });

    if (loadingState === 'loading') {
        return <LoadingSpinnerOverlay />;
    }

    return (
        <div>
            {hasError && <SugorokuErrorDialog />}
            {!nfts ? (
                <LoadingSpinnerOverlay />
            ) : (
                <>
                    <TitleContainer>
                        <Title>すごろく特典一覧</Title>
                    </TitleContainer>
                    <ArrowContainer>
                        <ArrowText onClick={handleArrowClick}>
                            ← すごろくゲームに戻る
                        </ArrowText>
                    </ArrowContainer>
                    <TabSelector
                        isFlipped={isPlayerListActive}
                        onFlipToPlayers={() => handleTabChange(true)}
                        onFlipToRewards={() => handleTabChange(false)}
                    />
                    {!showPlayerDetailScreen && !selectedPlayerCode ? (
                        <SeasonSelector
                            seasons={seasons}
                            selectedSeason={selectedSeason}
                            onSeasonChange={setSelectedSeason}
                        />
                    ) : (
                        <></>
                    )}
                    {isPlayerListActive ? (
                        <PlayerList selectedSeason={selectedSeason} />
                    ) : (
                        <Container>
                            {!filteredNfts || filteredNfts.length === 0 ? (
                                <EmptyMessage>NFTがありません。</EmptyMessage>
                            ) : filteredNfts.length === 1 ? (
                                <Content>
                                    <SugorokuNftDetailContent
                                        eventId={Number(
                                            filteredNfts[0].event.id
                                        )}
                                    />
                                </Content>
                            ) : (
                                <Content>
                                    <Grid>
                                        {filteredNfts.map((nft, index) => (
                                            <div key={index}>
                                                <SugorokuNFTCard
                                                    nft={nft}
                                                    isFlipped={
                                                        isPlayerListActive
                                                    }
                                                />
                                                <SugorokuNFTCardInfo
                                                    nft={nft}
                                                />
                                            </div>
                                        ))}
                                    </Grid>
                                </Content>
                            )}
                        </Container>
                    )}
                </>
            )}
        </div>
    );
};

export default SugorokuRewardList;
