import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dole_wai from '../assets/images/dole_wai.png';
import SugorokuReward from '../assets/images/sugoroku-reward.svg';
import { SugorokuItem } from '../types/sugorokuMasterType';
import Dialog from '../components/Dialog';
import { useDialog } from '../components/UseDialog';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { useNavigate } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { useSugorokuReward } from '../hooks/useSugorokuReward';

const PkGoalText = styled.div`
    margin-top: 20px;
    font-size: 42px;
    font-weight: 900;
    color: #d71920;
`;

const PlayerInfoText = styled.div`
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    text-align: center;
`;

const AnnotationText = styled.div`
    margin-top: 4px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;

    /* HTMLタグ内のスタイルを適用 */
    span {
        color: inherit;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RewardIcon = styled.img`
    margin-right: 10px;
`;

const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 50px;
    padding: 13px 0;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000000;
    border-radius: 25px;

    /* アクセシビリティ的によくないが、最初からネガティブな選択肢にフォーカスがあたっているのが気に食わないので… */
    &:focus-visible {
        outline: none;
    }
`;

interface RedButtonProps {
    $hasPlayerTextInItemCode?: boolean;
}

const RedButton = styled(StyledButton)<RedButtonProps>`
    margin-top: ${(props) =>
        props.$hasPlayerTextInItemCode ? '10px' : '20px'};
    font-weight: bold;
    color: white;
    background-color: #d71920;
    border: 1px solid #d71920;
`;

interface WhiteButtonProps {
    $hasPlayerTextInItemCode?: boolean;
}

const WhiteButton = styled(StyledButton)<WhiteButtonProps>`
    margin-top: ${(props) =>
        props.$hasPlayerTextInItemCode ? '10px' : '20px'};
    font-weight: bold;
    color: black;
    background-color: white;
    border: 1px solid black;
`;

/**
 * ダイアログ画像をラップするコンポーネント
 */
const DialogImageWrapper = styled.div`
    position: absolute;
    top: -95px;
    left: 50%;
    transform: translateX(-50%);
`;

const DialogImage = styled.img`
    width: 190px;
`;

interface PkScreenGoalProps {
    nextEventItem?: SugorokuItem;
    onPassClubPlayerCode?: (code: string | null) => void;
    clubPlayerCode?: string | null;
}
const PkScreenGoal: React.FC<PkScreenGoalProps> = ({
    nextEventItem,
    onPassClubPlayerCode,
}) => {
    const { setShowPlayerDetailScreen, setSelectedPlayerCode } =
        useSugorokuReward();
    const [showMessageDialogueContainer, setShowMessageDialogueContainer] =
        useState(false);
    const { ref } = useDialog();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessageDialogueContainer(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    const navigate = useNavigate();

    const handleOkClick = () => {
        ga4PushEvent(
            GA4_CUSTOM_EVENT.PRESSED_MINI_GAME_SUCCESS_DIALOG_BACK_BUTTON
        );
        // @TODO 余り出目をstate保持してMypageSugorokuコンポーネントを表示し、
        // そのぶん進ませる処理を書く
        window.location.reload();
    };

    const handlePlayerDetails = () => {
        ga4PushEvent(
            GA4_CUSTOM_EVENT.PRESSED_MINI_GAME_SUCCESS_DIALOG_PLAYER_BUTTON
        );
        if (nextEventItem && onPassClubPlayerCode) {
            setShowMessageDialogueContainer(false);
            setSelectedPlayerCode(ClubPlayerCode);
            setShowPlayerDetailScreen(true);
            navigate(PATH_MAPPING.SUGOROKU_REWARDS, {
                state: {
                    isFromPkScreenGoal: true,
                },
            });
        }
    };

    if (!nextEventItem) {
        return null;
    }
    const ClubPlayerCode = nextEventItem.clubPlayerCode;
    const hasPlayerTextInItemCode = nextEventItem.code.includes('PLAYER');
    const hasNftTextInItemCode = nextEventItem.code.includes('NFT');

    const sanitizeContent = (content: string | null) => {
        if (!content) {
            return '';
        }
        return content.replace(/<\/?p>/g, '');
    };

    return (
        <>
            {showMessageDialogueContainer && (
                <>
                    <Dialog
                        ref={ref}
                        isOpen={true}
                        DialogImageWrapper={
                            <DialogImageWrapper>
                                <DialogImage
                                    src={dole_wai}
                                    alt="DoleWaiMessage"
                                />
                            </DialogImageWrapper>
                        }
                        closeModal={() => {}}
                        showCloseButton={false}
                    >
                        <PkGoalText>アタリ!!</PkGoalText>
                        <PlayerInfoText>
                            {nextEventItem?.name}
                            ゲット！
                        </PlayerInfoText>
                        {hasNftTextInItemCode && (
                            <AnnotationText
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeContent(
                                        nextEventItem.content
                                    ),
                                }}
                            />
                        )}
                        <ButtonContainer>
                            {hasPlayerTextInItemCode ? (
                                <>
                                    <RedButton onClick={handlePlayerDetails}>
                                        <RewardIcon
                                            src={SugorokuReward}
                                            alt="Players Info Diary"
                                        />
                                        選手情報を確認
                                    </RedButton>
                                    <WhiteButton
                                        $hasPlayerTextInItemCode={
                                            hasPlayerTextInItemCode
                                        }
                                        onClick={handleOkClick}
                                    >
                                        戻る
                                    </WhiteButton>
                                </>
                            ) : (
                                <WhiteButton
                                    $hasPlayerTextInItemCode={
                                        hasPlayerTextInItemCode
                                    }
                                    onClick={handleOkClick}
                                >
                                    戻る
                                </WhiteButton>
                            )}
                        </ButtonContainer>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default PkScreenGoal;
