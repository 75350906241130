import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from './router.tsx';
import { SugorokuRewardProvider } from './contexts/SugorokuRewardContext';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <SugorokuRewardProvider>
            <RouterProvider router={router} />
        </SugorokuRewardProvider>
    </React.StrictMode>
);
