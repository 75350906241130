import { useContext } from 'react';
import { SugorokuRewardContext } from '../contexts/SugorokuRewardContext';

export const useSugorokuReward = () => {
    const context = useContext(SugorokuRewardContext);
    if (context === undefined) {
        throw new Error(
            'useSugorokuReward must be used within a SugorokuRewardProvider'
        );
    }
    return context;
};