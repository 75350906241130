import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MyPageSugoroku from '../routes/MyPageSugoroku';
import prize2025Season1st from '../assets/images/prize_2025_1st.png';
import bnrHelp from '../assets/images/bnr_help.png';
import imgAccess from '../assets/images/img_access.png';
import imgCheckin from '../assets/images/img_checkin.png';
import imgDiary from '../assets/images/img_diary.png';
import imgMiniGame from '../assets/images/img_mini_game.png';
import imgRanking from '../assets/images/img_ranking.png';
import tiltingDice from '../assets/images/tilting_dice.png';
import CloseIconButton from './CloseIconButton';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    padding: 0 20px 20px;
    padding-bottom: 80px;
    margin: 0 auto;
`;

/**
 * バナー画像
 */
const BnrImage = styled.img`
    /* +40pxは左右に当ててるpaddingの分 */
    width: calc(100% + 40px);
    margin-top: 33px;
`;

/**
 * ページタイトル、閉じるボタンのコンテナ
 */
const TitleAndCloseButtonContainer = styled.div`
    position: relative;
    width: 100%;
    margin-top: 26px;
    font-size: 24px;
    font-weight: 900;
    text-align: center;
`;

/**
 * 閉じるボタンのコンテナ
 */
const CloseButtonContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 0;
`;

/**
 * タブのコンテナ
 */
const TabContainer = styled.div`
    display: flex;
    align-items: end;

    /* +40pxは左右に当ててるpaddingの分 */
    width: calc(100% + 40px);
    margin-top: -17px;
`;

type TabProps = {
    $active: boolean;
};

/**
 * タブのスタイル
 */
const Tab = styled.button<TabProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ $active }) => ($active ? '51%' : '49%')};
    height: ${({ $active }) => ($active ? '47px' : '40px')};
    padding: 10px 0;
    clip-path: polygon(
        10px 0,
        calc(100% - 10px) 0,
        100% 10px,
        100% 100%,
        0 100%,
        0 10px
    );
    font-size: ${({ $active }) => ($active ? '18px' : '14px')};
    font-weight: 900;
    color: #ffffff;
    cursor: pointer;
    background-color: ${({ $active }) => ($active ? '#000000' : '#C0C0C0')};
    border: none;
`;

/**
 * インストラクションのコンテナ
 */
const InstructionContainer = styled.div`
    display: grid;
    row-gap: 50px;
    margin-top: 50px;
`;

/**
 * インストラクションのコンテンツのラッパー
 */
const InstructionContentWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 40px 0 30px;
    font-weight: 500;
    background-color: #ffffff;
    border: 2px solid #e40514;
    border-radius: 10px;
`;

/**
 * タイトルのラベル
 */
const InstructionTitleLabel = styled.div`
    /* 赤枠を基準にこのタイトルラベルを調整する */
    position: absolute;
    top: -22px;
    left: 50%;
    display: flex;
    align-items: center;
    font-weight: 700;
    transform: translateX(-50%);
`;

/**
 * 六角形のスタイル
 */
const InstructionHexgon = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 42px;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    font-size: 18px;
    line-height: 37px;
    color: #ffffff;
    text-align: center;
    background-color: #e40514;
`;

// 長方形のスタイル
const InstructionRectangle = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px 0 35px;
    margin-left: -30px;
    font-size: 17px;
    line-height: 37px;
    color: #ffffff;
    word-break: keep-all;
    background-color: #e40514;
    box-shadow: inset 30px 0 6px #00000029;
`;

const InstructionTiltingDice = styled.div`
    position: absolute;
    right: -18px;
    bottom: -23px;
`;

const InstructionTiltingDiceImage = styled.img`
    width: 48.68px;
`;

/**
 * インストラクション1内のコンテンツ1のラッパー
 */
const InstructionContent1 = styled.div`
    display: grid;
    row-gap: 20px;
    margin: 0 19px;
`;

/**
 * インストラクション2内のコンテンツ2のラッパー
 */
const InstructionContent2 = styled.div`
    margin: 0 38px;
`;

/**
 * インストラクション内の画像ラッパー
 */
const InstructionImageWrapper = styled.div`
    text-align: center;
`;

/**
 * インストラクション内のタイトル
 */
const InstructionTitle = styled.div`
    font-size: 14px;
    font-weight: 900;
    line-height: 23px;
    text-align: center;
`;

/**
 * インストラクション内の行のコンテンツ
 */
const InstructionRawContent = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
`;

/**
 * インストラクション内の行の中の画像
 */
const InstructionRawImage = styled.img`
    width: 100px;
    height: 100px;
`;

/**
 * インストラクション内の行の右側のコンテンツ
 */
const InstructionRawRightContent = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
`;

/**
 * インストラクション内の行の中のタイトル
 */
const InstructionRawTitle = styled.div`
    font-size: 14px;
    font-weight: 900;
    color: #e40514;
`;

/**
 * アイテム数を表示するラベル
 */
const ItemCountLabel = styled.span`
    padding: 2px 7px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: 900;
    line-height: 20px;
    color: #ffffff;
    background-color: #e40514;
`;

/**
 * インストラクション内の行の中のテキスト
 */
const InstructionRawText = styled.div`
    margin-top: 4px;
`;

/**
 * インストラクション内のミニゲーム画像
 */
const InstructionMiniGameImage = styled.img`
    max-width: 177px;
    height: auto;
`;

const InstructionRankingImage = styled.img`
    max-width: 80px;
    height: auto;
`;

/**
 * インストラクションのコンテンツ2のテキスト
 */
const InstructionContent2Text = styled.div`
    margin-top: 15.94px;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    word-break: keep-all;
`;

/**
 * 補足テキストのコンテンツ
 */
const SupplementalContent = styled.div`
    line-height: 17px;
`;

/**
 * 対象試合の補足テキストのタイトル
 */
const SupplementalTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
`;

/**
 * 対象試合の補足テキスト
 */
const SupplementalText = styled.span`
    display: block;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
`;

/**
 * 枠の下の補足テキスト
 */
const SupplementalTextBottom = styled(SupplementalText)`
    font-size: 13px;
`;

/**
 * リンクテキストのラッパー
 */
const LinkTextWrapper = styled.div`
    font-size: 16px;
    font-weight: 900;
    line-height: 37px;
    text-align: center;
`;

/**
 * リンクテキスト
 */
const LinkText = styled.a`
    color: #e40514;
    text-decoration: underline;
    cursor: pointer;
`;

/**
 * 賞品画像のラッパー
 */
const PrizeImageWrapper = styled.div`
    display: grid;

    /* 賞品画像の上部と左側に25px程度の余白があるため */
    /* 上部はXDから25px分の余白を削る */
    row-gap: 15px;
    margin-top: 25px;

    /* 左側の対応については右側にも25px分の余白を追加する */
    margin-right: 25px;
`;

/**
 * 賞品画像
 */
const PrizeImage = styled.img`
    width: 100%;
`;

// 申込方法・当選結果発表のコンテナ
const PrizeContent = styled.div`
    display: grid;
    row-gap: 24px;
    max-width: 350px;
    margin-top: 20px;
`;

/**
 * 申込方法・当選結果発表の行のコンテンツ
 */
const PrizeRowContent = styled.div`
    display: grid;
    row-gap: 8px;
    font-size: 14px;
`;

/**
 * 申込方法・当選結果発表のタイトル
 */
const PrizeBottomTitle = styled.div`
    width: 84px;
    padding: 0 10px;
    font-weight: 700;
    line-height: 28px;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
`;

/**
 * 申込方法・当選結果発表のテキスト
 */
const PrizeBottomText = styled.div`
    font-weight: 900;
    line-height: 23px;
`;

/**
 * マイページすごろくに戻るボタン
 */
const ReturnToMypageSugorokuButton = styled.button`
    padding: 13px 104px;
    margin-top: 60px;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    color: #000000;
    cursor: pointer;
    background-color: #ffffff;
    border: solid 1px #000000;
    border-radius: 25px;
`;

const SugorokuHelp: React.FC = () => {
    const [showMyPageSugorokuScreen, setShowMyPageSugorokuScreen] =
        useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const topRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_SUGOROKU_HELP_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    /**
     * スクロールしてページトップに移動する
     * @returns void
     */
    const scrollToTop = () => {
        if (!topRef.current) {
            return;
        }

        topRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

        // 0.3秒後にタブを切り替える
        setTimeout(() => {
            setActiveTab(1);
        }, 300);
    };

    return (
        <>
            {showMyPageSugorokuScreen ? (
                <MyPageSugoroku />
            ) : (
                <Container ref={topRef}>
                    <TitleAndCloseButtonContainer>
                        ヘルプ
                        <CloseButtonContainer>
                            <CloseIconButton
                                onClick={() =>
                                    setShowMyPageSugorokuScreen(true)
                                }
                            />
                        </CloseButtonContainer>
                    </TitleAndCloseButtonContainer>
                    <BnrImage src={bnrHelp} alt={'bnrHelp'} />

                    <TabContainer>
                        <Tab
                            onClick={() => setActiveTab(0)}
                            $active={activeTab === 0}
                        >
                            <span>遊び方</span>
                        </Tab>
                        <Tab
                            onClick={() => setActiveTab(1)}
                            $active={activeTab === 1}
                        >
                            <span>賞品・申込方法</span>
                        </Tab>
                    </TabContainer>
                    {activeTab === 0 ? (
                        <>
                            <InstructionContainer>
                                {/* 1つめのコンテンツ */}
                                <InstructionContentWrapper>
                                    <InstructionTitleLabel>
                                        <InstructionHexgon>1</InstructionHexgon>
                                        <InstructionRectangle>
                                            サイコロを振ってマスを進める
                                        </InstructionRectangle>
                                    </InstructionTitleLabel>
                                    <InstructionContent1>
                                        <InstructionTitle>
                                            サイコロは、以下の方法で
                                            <br />
                                            振るチャンスを獲得できます。
                                        </InstructionTitle>
                                        {/* ログイン */}
                                        <InstructionRawContent>
                                            <InstructionImageWrapper>
                                                <InstructionRawImage
                                                    src={imgAccess}
                                                    alt={'imgAccess'}
                                                ></InstructionRawImage>
                                            </InstructionImageWrapper>
                                            <InstructionRawRightContent>
                                                <InstructionRawTitle>
                                                    1日1回のアクセス
                                                    <ItemCountLabel>
                                                        1回
                                                    </ItemCountLabel>
                                                </InstructionRawTitle>
                                                <InstructionRawText>
                                                    WEBアプリに
                                                    <br />
                                                    アクセス（ログイン）することで
                                                    <br />
                                                    1回分獲得できます。
                                                </InstructionRawText>
                                            </InstructionRawRightContent>
                                        </InstructionRawContent>
                                        {/* チェックイン */}
                                        <InstructionRawContent>
                                            <InstructionImageWrapper>
                                                <InstructionRawImage
                                                    src={imgCheckin}
                                                    alt={'imgCheckin'}
                                                ></InstructionRawImage>
                                            </InstructionImageWrapper>
                                            <InstructionRawRightContent>
                                                <InstructionRawTitle>
                                                    来場チェック
                                                    <ItemCountLabel>
                                                        3回
                                                    </ItemCountLabel>
                                                </InstructionRawTitle>
                                                <InstructionRawText>
                                                    対象試合での「来場チェック」で
                                                    3回分獲得できます。
                                                </InstructionRawText>
                                            </InstructionRawRightContent>
                                        </InstructionRawContent>
                                        {/* 写真＆日記登録 */}
                                        <InstructionRawContent>
                                            <InstructionImageWrapper>
                                                <InstructionRawImage
                                                    src={imgDiary}
                                                    alt={'imgDiary'}
                                                ></InstructionRawImage>
                                            </InstructionImageWrapper>
                                            <InstructionRawRightContent>
                                                <InstructionRawTitle>
                                                    写真＆日記登録
                                                    <ItemCountLabel>
                                                        各1回
                                                    </ItemCountLabel>
                                                </InstructionRawTitle>
                                                <InstructionRawText>
                                                    対象試合での、
                                                    <br />
                                                    アルバムへの写真登録および
                                                    <br />
                                                    日記登録で各1回分獲得できます。
                                                </InstructionRawText>
                                            </InstructionRawRightContent>
                                        </InstructionRawContent>
                                        <SupplementalContent>
                                            <SupplementalTitle>
                                                来場チェック および
                                                <br />
                                                写真＆日記登録によるサイコロ付与について
                                            </SupplementalTitle>
                                            <SupplementalText>
                                                3/9(日)以降の明治安田J2リーグおよびJリーグYBCルヴァンカップのHOME戦が対象となります。(それ以前の試合に紐づく来場チェック・写真＆日記は対象外です)
                                                <br />
                                                なお、対象試合が追加される際は、コンサドーレ公式X・CONFTアプリ内のお知らせ機能にて発信いたします。
                                            </SupplementalText>
                                        </SupplementalContent>
                                    </InstructionContent1>
                                </InstructionContentWrapper>
                                {/* 2つめのコンテンツ */}
                                <InstructionContentWrapper>
                                    <InstructionTitleLabel>
                                        <InstructionHexgon>2</InstructionHexgon>
                                        <InstructionRectangle>
                                            進んだマスを競う
                                        </InstructionRectangle>
                                    </InstructionTitleLabel>
                                    <InstructionContent2>
                                        <InstructionImageWrapper>
                                            <InstructionRankingImage
                                                src={imgRanking}
                                                alt={'imgRanking'}
                                            ></InstructionRankingImage>
                                        </InstructionImageWrapper>
                                        <InstructionContent2Text>
                                            毎月、進んだマスを競いましょう。
                                        </InstructionContent2Text>
                                    </InstructionContent2>
                                    <InstructionTiltingDice>
                                        <InstructionTiltingDiceImage
                                            src={tiltingDice}
                                            alt={'tiltingDice'}
                                        ></InstructionTiltingDiceImage>
                                    </InstructionTiltingDice>
                                </InstructionContentWrapper>
                                {/* 3つめのコンテンツ */}
                                <InstructionContentWrapper>
                                    <InstructionTitleLabel>
                                        <InstructionHexgon>3</InstructionHexgon>
                                        <InstructionRectangle>
                                            すごろく途中でミニゲーム
                                        </InstructionRectangle>
                                    </InstructionTitleLabel>
                                    <InstructionContent1>
                                        <InstructionImageWrapper>
                                            <InstructionMiniGameImage
                                                src={imgMiniGame}
                                                alt={'imgMiniGame'}
                                            ></InstructionMiniGameImage>
                                        </InstructionImageWrapper>
                                        <InstructionContent2Text>
                                            途中のミニゲームをクリアしつつ、
                                            <br />
                                            ノルマを達成すると、
                                            <br />
                                            「選手コンテンツ」「すごろくNFT」がもらえます！
                                            <br />
                                            <br />
                                            毎月上位5名には、
                                            <br />
                                            翌月以降の対象試合（JリーグHOME戦）の
                                            ペア観戦チケットをプレゼント
                                            <br />
                                            <br />
                                            <SupplementalContent>
                                                <SupplementalText>
                                                    ※同率順位にて上位5名以上の場合、事務局による抽選となります。
                                                    <br />
                                                    ※観戦試合のご希望選択はお受けできません。
                                                </SupplementalText>
                                            </SupplementalContent>
                                        </InstructionContent2Text>
                                    </InstructionContent1>
                                    <InstructionTiltingDice>
                                        <InstructionTiltingDiceImage
                                            src={tiltingDice}
                                            alt={'tiltingDice'}
                                        ></InstructionTiltingDiceImage>
                                    </InstructionTiltingDice>
                                </InstructionContentWrapper>
                                <SupplementalContent>
                                    <SupplementalTextBottom>
                                        ・2025シーズンのすごろく期間は、3月9日～11月29日（予定）です。
                                        <br />
                                        <br />
                                        ※期間は状況により変更する可能性がございます。
                                    </SupplementalTextBottom>
                                </SupplementalContent>
                                <LinkTextWrapper>
                                    賞品・申込方法は
                                    <LinkText onClick={scrollToTop}>
                                        こちら
                                    </LinkText>
                                </LinkTextWrapper>
                            </InstructionContainer>
                        </>
                    ) : (
                        <>
                            <PrizeImageWrapper>
                                <PrizeImage
                                    src={prize2025Season1st}
                                    alt="prize2025Season1st"
                                />
                            </PrizeImageWrapper>
                            <PrizeContent>
                                <PrizeRowContent>
                                    <PrizeBottomText>
                                        年間賞も検討・準備中！
                                        <br />
                                        確定次第、アナウンス致します。
                                        <br />
                                        <br />
                                    </PrizeBottomText>
                                    <PrizeBottomTitle>
                                        申 込 方 法
                                    </PrizeBottomTitle>
                                    <PrizeBottomText>
                                        毎月のすごろくランキング上位5名が自動で対象となります。
                                        <br />
                                        ※対象者が５名以上の場合、事務局による抽選となります
                                        <br />
                                        ※対象試合はご希望に添えない場合がございますので予めご了承ください
                                    </PrizeBottomText>
                                </PrizeRowContent>
                                <PrizeRowContent>
                                    <PrizeBottomTitle>
                                        当選結果発表
                                    </PrizeBottomTitle>
                                    <PrizeBottomText>
                                        当選した方にのみ、
                                        CONFTアプリ内のお知らせ機能にて通知。
                                        <br />
                                    </PrizeBottomText>
                                </PrizeRowContent>
                            </PrizeContent>
                        </>
                    )}
                    <ReturnToMypageSugorokuButton
                        onClick={() => setShowMyPageSugorokuScreen(true)}
                    >
                        戻る
                    </ReturnToMypageSugorokuButton>
                </Container>
            )}
        </>
    );
};

export default SugorokuHelp;
