import { createAxiosInstance } from '../lib/createAxiosInstance';
import { API_PATHS } from './constants/apiPaths';
import GetSugorokuRankingResponseBody from './types/getSugorokuRankingType';
import { AxiosResponse } from 'axios';

/**
 * すごろくランキング取得APIを呼び出す
 * @returns {GetSugorokuRankingResponseBody} すごろくランキング情報
 * @throws {Error} API呼び出しに失敗した場合にエラーを投げる
 */
export const getSugorokuRanking =
    async (): Promise<GetSugorokuRankingResponseBody> => {
        try {
            const instance = createAxiosInstance();
            const response: AxiosResponse = await instance.get(
                API_PATHS.GET_SUGOROKU_RANKING
            );
            const responseBody: GetSugorokuRankingResponseBody =
                response.data ?? {};
            return responseBody;
        } catch (error) {
            console.error('Error fetching sugoroku ranking:', error);
            throw error;
        }
    };
