import LocalStorageManager from './LocalStorageManager';
import { SugorokuMaster } from '../types/sugorokuMasterType';
import { ClubPlayersGroupedByArea } from '../types/clubPlayersGroupedByArea';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';

/**
 * APIから取得したすごろくマスタデータをLocalStorageに保存したり読み出したりするマネージャー
 */
class SugorokuMasterManager {
    /**
     * すごろくマスタ全部入れる
     * @param sugorokuMaster すごろくマスタ
     */
    static setSugorokuMaster(sugorokuMaster: SugorokuMaster) {
        SugorokuMasterManager._setMap(sugorokuMaster.sugorokuMap);
        SugorokuMasterManager._setClubPlayers(sugorokuMaster.clubPlayers);
    }

    /**
     * すごろくマスタ全部消す
     */
    static clearSugorokuMaster() {
        LocalStorageManager.deleteLocalStorageValue(
            LOCAL_STORAGE_KEYS.SUGOROKU_MASTER
        );
        LocalStorageManager.deleteLocalStorageValue(
            LOCAL_STORAGE_KEYS.PLAYERS_MASTER
        );
    }

    /**
     * LocalStorageに保存されているマップを取得する
     * @returns マップ
     */
    static getMap(): SugorokuMaster | null {
        const data = LocalStorageManager.getLocalStorageValueAsJSON(
            LOCAL_STORAGE_KEYS.SUGOROKU_MASTER
        );
        if (!data) {
            return null;
        }
        return data;
    }

    /**
     * LocalStorageに保存されている、すごろくマスターの終了日時をチェックし、期間外の場合はデータを削除する
     */
    static checkAndClearExpiredMaster(): void {
        // ローカルストレージからすごろくマスターデータを取得
        const sugorokuMaster = this.getMap();

        // データが存在し、sugorokuMapプロパティが存在することを確認
        if (sugorokuMaster?.sugorokuMap) {
            // すごろくの終了日時を取得
            const endDatetime = new Date(
                sugorokuMaster.sugorokuMap.endDatetime
            );
            // 現在の日時がすごろくの終了日時を過ぎているかチェック
            if (new Date() > endDatetime) {
                // 期限切れの場合、ローカルストレージからすごろくマスターデータを削除
                this.clearSugorokuMaster();
            }
        }
    }

    /**
     * LocalStorageに保存されている選手を取得する
     * @returns 選手データ
     */
    static getClubPlayers(): SugorokuMaster['clubPlayers'] {
        const data = LocalStorageManager.getLocalStorageValueAsJSON(
            LOCAL_STORAGE_KEYS.PLAYERS_MASTER
        );
        if (!data) {
            return [];
        }
        return data;
    }

    /**
     * LocalStorageに保存されている選手をエリアでグルーピングして取得する
     * @returns エリアごとの選手データ
     */
    static getClubPlayersGroupedByArea(): ClubPlayersGroupedByArea[] {
        const players = SugorokuMasterManager.getClubPlayers();
        // area_code（文字列）、player_code（文字列）昇順にソートする。
        const sortedPlayers = Array.from(players).sort((a, b) => {
            if (a.areaCode === b.areaCode) {
                return a.code.localeCompare(b.code);
            }
            return a.areaCode.localeCompare(b.areaCode);
        });
        const groupedPlayers = sortedPlayers.reduce((acc, player) => {
            const areaName = player.areaName;
            // findは遅いからforで探す
            let targetArea;
            for (let i = 0; i < acc.length; i++) {
                if (acc[i].area === areaName) {
                    targetArea = acc[i];
                    break;
                }
            }
            if (targetArea) {
                targetArea.players.push(player);
            } else {
                acc.push({
                    area: areaName,
                    players: [player],
                });
            }
            return acc;
        }, [] as ClubPlayersGroupedByArea[]);

        return groupedPlayers;
    }

    /**
     * APIから取得したマップをLocalStorageに保存する
     *
     * @param sugorokuMap マップ
     */
    private static _setMap(sugorokuMap: SugorokuMaster['sugorokuMap']) {
        // mapは毎回入れ直してもいい
        const toBeSetData: {
            sugorokuMap: SugorokuMaster['sugorokuMap'];
        } = {
            sugorokuMap: sugorokuMap,
        };
        LocalStorageManager.setLocalStorageValue(
            LOCAL_STORAGE_KEYS.SUGOROKU_MASTER,
            JSON.stringify(toBeSetData)
        );
    }

    /**
     * APIから取得した選手をLocalStorageに保存する
     * @param clubPlayers 選手
     */
    private static _setClubPlayers(clubPlayers: SugorokuMaster['clubPlayers']) {
        // 選手はバージョンが変わらない限り返ってこない。返ってこなかったら今の値を使う
        const currentData = LocalStorageManager.getLocalStorageValueAsJSON(
            LOCAL_STORAGE_KEYS.PLAYERS_MASTER
        );
        const toBeSetPlayers =
            clubPlayers.length > 1
                ? clubPlayers
                : currentData?.clubPlayers ?? [];

        LocalStorageManager.setLocalStorageValue(
            LOCAL_STORAGE_KEYS.PLAYERS_MASTER,
            JSON.stringify(toBeSetPlayers)
        );
    }
}

export default SugorokuMasterManager;
