/**
 * すごろくの文字列から特定の年度のデータかどうかを判定する汎用的な関数
 * @param text 判定対象の文字列（すごろくマップ名やシーズン名）
 * @param year 判定する年度（文字列）
 * @returns 指定された年度のデータかどうか
 */
export const isSugorokuSeasonYear = (text: string, year: string): boolean => {
    return text.includes(year);
};

/**
 * すごろくの2024年度のデータかどうかを判定する関数
 * @param text 判定対象の文字列（すごろくマップ名やシーズン名）
 * @returns 2024年度のすごろくデータかどうか
 */
export const isSugorokuSeason2024 = (text: string): boolean => {
    return isSugorokuSeasonYear(text, '2024');
};
