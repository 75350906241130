import { SugorokuMaster } from '../types/sugorokuMasterType';

/**
 * 現在の位置から次のステージイベントまでのマス数を計算する
 * @param currentCell 現在のマス目
 * @param sugorokuMaster すごろくマスターデータ
 * @returns 次のステージイベントまでのマス数
 */
export const calculateTilesToNextEvent = (
    currentCell: number,
    sugorokuMaster: SugorokuMaster | null
): number => {
    if (!sugorokuMaster?.sugorokuMap?.stages) {
        return 0;
    }

    let currentStageIndex = 0;
    let passedCells = 0;

    // 現在のステージを特定
    for (const stage of sugorokuMaster.sugorokuMap.stages) {
        const stageTotalCells = stage.totalCells || 0;

        if (passedCells + stageTotalCells > currentCell) {
            // 現在のステージ内での相対位置を計算
            const relativePosition = currentCell - passedCells;

            // このステージにイベントがある場合
            if (stage.events && stage.events.length > 0) {
                // このステージ内で次のイベントを探す
                for (const event of stage.events) {
                    if (event.cell > relativePosition) {
                        return event.cell - relativePosition;
                    }
                }
            }

            // このステージに次のイベントがない場合、次のステージのイベントまでの距離を計算
            let remainingCells = stageTotalCells - relativePosition;
            let nextStageIndex = currentStageIndex + 1;

            while (nextStageIndex < sugorokuMaster.sugorokuMap.stages.length) {
                const nextStage =
                    sugorokuMaster.sugorokuMap.stages[nextStageIndex];
                if (nextStage.events && nextStage.events.length > 0) {
                    return remainingCells + nextStage.events[0].cell;
                }
                remainingCells += nextStage.totalCells || 0;
                nextStageIndex++;
            }
            return 0;
        }

        passedCells += stageTotalCells;
        currentStageIndex++;
    }

    return 0;
};

/**
 * 現在の位置から指定したイベントマスまでのマス数を計算する
 * @param currentCell 現在のマス目
 * @param sugorokuMaster すごろくマスターデータ
 * @param fromLast 最後から何番目のイベントマスを対象とするか（1: 最後のイベント、2: 最後から2番目のイベント...）
 * @returns イベントマスまでのマス数
 */
export const calculateTilesToLastEvent = (
    currentCell: number,
    sugorokuMaster: SugorokuMaster | null,
    fromLast: number = 1
): number => {
    if (!sugorokuMaster?.sugorokuMap?.stages) {
        return 0;
    }

    // イベントを持つステージを抽出
    const stagesWithEvents = sugorokuMaster.sugorokuMap.stages.filter(
        (stage) => stage.events && stage.events.length > 0
    );

    // 対象のイベントを持つステージを特定
    const targetStageIndex = stagesWithEvents.length - fromLast;
    if (targetStageIndex < 0) return 0;
    const targetStage = stagesWithEvents[targetStageIndex];

    let passedCells = 0;
    let totalDistance = 0;

    // 現在のステージまでのマス数を計算
    for (const stage of sugorokuMaster.sugorokuMap.stages) {
        const stageTotalCells = stage.totalCells || 0;

        // 現在位置を含むステージを見つけた場合
        if (passedCells + stageTotalCells > currentCell) {
            const relativePosition = currentCell - passedCells;
            totalDistance = stageTotalCells - relativePosition;

            // 残りのステージを処理
            const currentStageIndex =
                sugorokuMaster.sugorokuMap.stages.indexOf(stage);
            const remainingStages = sugorokuMaster.sugorokuMap.stages.slice(
                currentStageIndex + 1
            );

            for (const remainingStage of remainingStages) {
                if (remainingStage === targetStage) {
                    // 目標のステージに到達したら、イベントマスまでの距離を加算
                    return totalDistance + remainingStage.events![0].cell;
                }
                totalDistance += remainingStage.totalCells || 0;
            }

            // 現在のステージが目標のステージの場合
            if (stage === targetStage) {
                return stage.events![0].cell - relativePosition;
            }

            return totalDistance;
        }

        // 目標のステージを通り過ぎた場合
        if (stage === targetStage) {
            return 0;
        }

        passedCells += stageTotalCells;
    }

    return 0;
};
