import { getNfts } from '../api/getNfts';
import { defer } from 'react-router-dom';
import { EVENT_TYPE } from '../constants/eventType';

/**
 * ページ描画前に来場履歴を取得する
 *
 * @returns 来場履歴データ
 */
export const nftLoader = async () => {
    try {
        const Nfts = getNfts(EVENT_TYPE.MATCH);
        return defer({ nfts: Nfts });
    } catch (error) {
        // TODO エラーハンドリング実装時に考える
        return [];
    }
};
