import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';

// EventLinkコンポーネントのプロパティ型を定義
type EventLinkProps = {
    eventId?: string | number; // イベントIDを受け取るプロパティ (オプショナル)
    children: React.ReactNode; // 子要素を受け取るプロパティ
};

// react-router-domのLinkコンポーネントをスタイル付きで拡張したコンポーネント
export const StyledLink = styled(RouterLink)`
    color: inherit;
    text-decoration: none;
`;

/**
 * EventLinkコンポーネント (NFT詳細ページへのリンクコンポーネント)
 * @param eventId イベントID
 * @param children 子要素
 * @returns すごろくNFT詳細ページへのリンクコンポーネント
 */
export const SugorokuEventLink: React.FC<EventLinkProps> = ({
    eventId,
    children,
}) => {
    // StyledLinkコンポーネントを使ってリンクを作成し、子要素をレンダリング
    return (
        <StyledLink
            to={generatePath(PATH_MAPPING.SUGOROKU_NFT_DETAILS, {
                eventId: eventId,
            })}
        >
            {children}
        </StyledLink>
    );
};

export default SugorokuEventLink;
