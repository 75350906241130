import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayerDetail from './PlayerDetail';
import { getAccountSugorokuProgress } from '../api/getAccountSugorokuProgress';
import SugorokuMasterManager from '../lib/sugorokuMasterManager';
import { SugorokuErrorDialog } from './SugorokuErrorDialog';
import { ClubPlayers } from '../types/sugorokuMasterType';
import { ClubPlayersGroupedByArea } from '../types/clubPlayersGroupedByArea';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import unknown from '../assets/images/unknown.png';
import { useSugorokuReward } from '../hooks/useSugorokuReward';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    padding: 0 20px 20px;
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
`;

/**
 * エリアごとの四角要素
 */
const AreaContainer = styled.div`
    position: relative;
    padding-top: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #0000001a;
`;

/**
 * ○○エリア という部分
 */
const AreaText = styled.div`
    position: absolute;
    left: -12px;
    z-index: 2;
    padding: 10px 25px;
    font-weight: 900;
    color: white;
    background-color: #e40514;
    border-radius: 5px 5px 5px 0;

    &::after {
        position: absolute;
        bottom: -12px;
        left: 5px;
        z-index: 1;
        width: 0;
        height: 0;

        /* 右上が直角である、二等辺直角三角形を作成する */
        content: '';
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid #bf020f;
        rotate: -45deg;
    }
`;

/**
 * エリアごとの選手リスト
 */
const PlayerListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px 5px;
    padding: 0 12px 30px;
    margin-top: 60px;
`;

/**
 * 選手ごとのコンテナ
 */
const PlayerContainer = styled.div<{ $isUnlocked: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: ${(props) => (props.$isUnlocked ? 'pointer' : 'default')};
`;

/**
 * プロフィール画像
 */
const ProfileImageIcon = styled.img<{ $isUnlocked: boolean }>`
    position: relative;
    width: 100px;
    height: 100px;
    border: 2px solid black;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
`;

/**
 * ポジション、選手名のコンテナ
 */
const PlayerProfileTextContainer = styled.div`
    display: grid;
    row-gap: 2px;
    margin-top: 6px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
`;

/**
 * ポジション名
 */
const PlayerPositionText = styled.div<{ $isUnlocked: boolean }>`
    /* @HACK ホントはsubgridで書きたかったが、時間がなかったのでネガティブマージンでごまかす。 */
    margin-top: ${(props) => (props.$isUnlocked ? '0' : '-6px')};
    visibility: ${(props) => (props.$isUnlocked ? 'visible' : 'hidden')};
`;

/**
 * 選手名
 */
const PlayerNameText = styled.div<{ $isUnlocked: boolean }>`
    max-width: 100px;
    font-size: 14px;
    color: ${(props) => (props.$isUnlocked ? 'black' : '#BCBCBC')};
    word-break: keep-all;
`;

// ここで使う型を定義する
export interface PlayersWithUnlocked extends ClubPlayers {
    // 代入前まではundefinedになるので、undefinedを許容する
    isUnlocked?: boolean;
}

interface ClubPlayersGroupedByAreaWithUnlocked
    extends ClubPlayersGroupedByArea {
    players: PlayersWithUnlocked[];
}

interface Props {
    selectedSeason: number;
}

const EmptyMessage = styled.p`
    max-width: 400px;
    margin-top: 20px;
    margin-bottom: 100px;
    font-size: 14px;
    text-align: center;
`;

const PlayerList: React.FC<Props> = ({ selectedSeason }) => {
    const {
        showPlayerDetailScreen,
        setShowPlayerDetailScreen,
        selectedPlayerCode,
        setSelectedPlayerCode,
    } = useSugorokuReward();
    const [hasError, setHasError] = useState(false);
    const [groupedPlayers, setGroupedPlayers] = useState<
        ClubPlayersGroupedByAreaWithUnlocked[]
    >([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_SUGOROKU_PLAYER_LIST_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    const location = useLocation();
    const isFromPkScreenGoal = location.state?.isFromPkScreenGoal || false;

    useEffect(() => {
        if (!isFromPkScreenGoal) {
            setSelectedPlayerCode(null);
            setShowPlayerDetailScreen(false);
        }
    }, [isFromPkScreenGoal, setSelectedPlayerCode, setShowPlayerDetailScreen]);

    useEffect(() => {
        const fetchAccountSugorokuProgress = async () => {
            try {
                const progress = await getAccountSugorokuProgress();
                if (!progress) {
                    throw new Error(
                        'Failed to fetch account sugoroku progress'
                    );
                }
                const groupedPlayers: ClubPlayersGroupedByAreaWithUnlocked[] =
                    SugorokuMasterManager.getClubPlayersGroupedByArea();

                // シーズンでフィルタリングして、エリアごとにグループ化
                const filteredGroupedPlayers = groupedPlayers
                    .map((group) => ({
                        ...group,
                        players: group.players.filter(
                            (player) => player.season.id === selectedSeason
                        ),
                    }))
                    .filter((group) => group.players.length > 0);

                // プレイヤーのロック状態を設定
                for (const area of filteredGroupedPlayers) {
                    for (const player of area.players) {
                        const playerCode = player.code;
                        let isUnlocked = false;
                        for (const item of progress.items) {
                            if (item.club_player_code === playerCode) {
                                isUnlocked = true;
                                break;
                            }
                        }
                        player.isUnlocked = isUnlocked;
                    }
                }
                setGroupedPlayers(filteredGroupedPlayers);
            } catch (error) {
                console.error(error);
                setHasError(true);
            }
        };
        fetchAccountSugorokuProgress();
    }, [selectedSeason]);

    const handlePlayerClick = (code: string, isUnlocked: boolean) => {
        if (isUnlocked) {
            setSelectedPlayerCode(code);
            setShowPlayerDetailScreen(true);
        }
    };

    return (
        <>
            {hasError && <SugorokuErrorDialog />}
            {showPlayerDetailScreen && selectedPlayerCode ? (
                <PlayerDetail code={selectedPlayerCode} />
            ) : (
                <Container>
                    {groupedPlayers.length === 0 ? (
                        <EmptyMessage>選手がいません。</EmptyMessage>
                    ) : (
                        groupedPlayers.map(({ area, players }, areaIndex) => (
                            <React.Fragment key={areaIndex}>
                                <AreaContainer>
                                    <AreaText>{area}エリア</AreaText>
                                    <PlayerListContainer>
                                        {players.map(
                                            (
                                                {
                                                    code,
                                                    name,
                                                    position,
                                                    iconImageUrl,
                                                    isUnlocked,
                                                },
                                                playerIndex
                                            ) => (
                                                <React.Fragment
                                                    key={`${areaIndex}-${playerIndex}`}
                                                >
                                                    <PlayerContainer
                                                        $isUnlocked={
                                                            isUnlocked ?? false
                                                        }
                                                        onClick={() =>
                                                            handlePlayerClick(
                                                                code,
                                                                isUnlocked ??
                                                                    false
                                                            )
                                                        }
                                                    >
                                                        <ProfileImageIcon
                                                            src={
                                                                isUnlocked
                                                                    ? iconImageUrl
                                                                    : unknown
                                                            }
                                                            alt={
                                                                isUnlocked
                                                                    ? `Player ${name}`
                                                                    : '???'
                                                            }
                                                            $isUnlocked={
                                                                isUnlocked ??
                                                                false
                                                            }
                                                        />
                                                        <PlayerProfileTextContainer>
                                                            <PlayerPositionText
                                                                $isUnlocked={
                                                                    isUnlocked ??
                                                                    false
                                                                }
                                                            >
                                                                {position}
                                                            </PlayerPositionText>

                                                            <PlayerNameText
                                                                $isUnlocked={
                                                                    isUnlocked ??
                                                                    false
                                                                }
                                                            >
                                                                {isUnlocked
                                                                    ? name
                                                                    : '未取得'}
                                                            </PlayerNameText>
                                                        </PlayerProfileTextContainer>
                                                    </PlayerContainer>
                                                </React.Fragment>
                                            )
                                        )}
                                    </PlayerListContainer>
                                </AreaContainer>
                            </React.Fragment>
                        ))
                    )}
                </Container>
            )}
        </>
    );
};

export default PlayerList;
