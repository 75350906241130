import React, { createContext, ReactNode } from 'react';

type SugorokuRewardContextType = {
    isPlayerListActive: boolean;
    setIsPlayerListActive: (value: boolean) => void;
    selectedSeason: string;
    setSelectedSeason: (value: string) => void;
    showPlayerDetailScreen: boolean;
    setShowPlayerDetailScreen: (value: boolean) => void;
    selectedPlayerCode: string | null;
    setSelectedPlayerCode: (value: string | null) => void;
};

export const SugorokuRewardContext = createContext<
    SugorokuRewardContextType | undefined
>(undefined);

type Props = {
    children: ReactNode;
};

export const SugorokuRewardProvider: React.FC<Props> = ({ children }) => {
    const [isPlayerListActive, setIsPlayerListActive] = React.useState(true);
    const [selectedSeason, setSelectedSeason] = React.useState('');
    const [showPlayerDetailScreen, setShowPlayerDetailScreen] =
        React.useState(false);
    const [selectedPlayerCode, setSelectedPlayerCode] = React.useState<
        string | null
    >(null);
    return (
        <SugorokuRewardContext.Provider
            value={{
                isPlayerListActive,
                setIsPlayerListActive,
                selectedSeason,
                setSelectedSeason,
                showPlayerDetailScreen,
                setShowPlayerDetailScreen,
                selectedPlayerCode,
                setSelectedPlayerCode,
            }}
        >
            {children}
        </SugorokuRewardContext.Provider>
    );
};
