import SugorokuMasterManager from './sugorokuMasterManager';
/*
    すごろくゲームが開催中かどうかを判定する
    開催中の場合はtrueを返す

    @return {boolean} すごろくゲームが開催中かどうか
*/
export const isSugorokuActive = () => {
    const map = SugorokuMasterManager.getMap()?.sugorokuMap;
    if (!map) {
        return false;
    }
    const now: Date = new Date();
    return (
        // 開始日時 <= 現在日時 < 終了日時
        new Date(map.startDatetime) <= now && now < new Date(map.endDatetime)
    );
};
