import Nft from '../types/nftType';
import { Season } from '../components/SeasonSelector';
import { PlayersWithUnlocked } from '../components/PlayerList';

export const extractSeasonFromClubPlayers = (
    clubPlayers: PlayersWithUnlocked[]
): Season[] => {
    const seasons = clubPlayers.map((clubPlayer) => ({
        id: clubPlayer.season.id,
        name: clubPlayer.season.name,
    }));
    return seasons;
};

export const extractSeasonFromNfts = (nfts: Nft[]): Season[] => {
    const seasons = nfts.map((nft) => ({
        id: nft.event.season.id,
        name: nft.event.season.name,
    }));
    return seasons;
};
