import { SugorokuMaster } from '../../types/sugorokuMasterType';

export const sugorokuMasterValidator = (
    sugorokuMaster: SugorokuMaster
): boolean => {
    // そもそもマップがあるか
    if (!sugorokuMaster.sugorokuMap) {
        return false;
    }

    // マップのバリデーション
    if (typeof sugorokuMaster.sugorokuMap.code !== 'string') {
        return false;
    }
    if (typeof sugorokuMaster.sugorokuMap.version !== 'string') {
        return false;
    }
    if (typeof sugorokuMaster.sugorokuMap.title !== 'string') {
        return false;
    }
    if (typeof sugorokuMaster.sugorokuMap.startDatetime !== 'string') {
        return false;
    }
    if (typeof sugorokuMaster.sugorokuMap.endDatetime !== 'string') {
        return false;
    }
    if (!Array.isArray(sugorokuMaster.sugorokuMap.stages)) {
        return false;
    }

    // ステージのバリデーション
    for (const stage of sugorokuMaster.sugorokuMap.stages) {
        if (typeof stage.sugorokuMapCode !== 'string') {
            return false;
        }
        if (typeof stage.code !== 'string') {
            return false;
        }
        if (typeof stage.areaCode !== 'string') {
            return false;
        }
        if (typeof stage.areaName !== 'string') {
            return false;
        }
        if (typeof stage.isFinal !== 'boolean') {
            return false;
        }
        if (typeof stage.totalCells !== 'number') {
            return false;
        }
        if (!stage.events && !Array.isArray(stage.events)) {
            return false;
        }

        // イベントのバリデーション
        for (const event of stage.events) {
            if (typeof event.sugorokuStageCode !== 'string') {
                return false;
            }
            if (typeof event.code !== 'string') {
                return false;
            }
            if (typeof event.title !== 'string') {
                return false;
            }
            if (typeof event.cell !== 'number') {
                return false;
            }
            if (!event.itemCode && typeof event.itemCode !== 'string') {
                return false;
            }
            if (event.item !== null && typeof event.item !== 'object') {
                return false;
            }
            if (event.item && typeof event.item.code !== 'string') {
                return false;
            }
            if (event.item && typeof event.item.name !== 'string') {
                return false;
            }
            if (event.item && typeof event.item.type !== 'string') {
                return false;
            }
            if (
                event.item &&
                event.item.sugorokuMapCode !== null &&
                typeof event.item.sugorokuMapCode !== 'string'
            ) {
                return false;
            }
            if (
                event.item &&
                event.item.clubPlayerCode !== null &&
                typeof event.item.clubPlayerCode !== 'string'
            ) {
                return false;
            }
            if (
                event.item &&
                event.item.clubPlayerName !== null &&
                typeof event.item.clubPlayerName !== 'string'
            ) {
                return false;
            }
            if (typeof event.pk !== 'number') {
                return false;
            }
        }
    }

    // 選手のバリデーション
    if (!Array.isArray(sugorokuMaster.clubPlayers)) {
        return false;
    }
    for (const player of sugorokuMaster.clubPlayers) {
        if (typeof player.code !== 'string') {
            return false;
        }
        if (typeof player.name !== 'string') {
            return false;
        }
        if (typeof player.position !== 'string') {
            return false;
        }
        if (typeof player.hometown !== 'string') {
            return false;
        }
        if (typeof player.numberedHomeTown !== 'string') {
            return false;
        }
        if (typeof player.birthday !== 'string') {
            return false;
        }
        if (typeof player.height !== 'number') {
            return false;
        }
        if (typeof player.weight !== 'number') {
            return false;
        }
        if (typeof player.iconImageUrl !== 'string') {
            return false;
        }
        if (typeof player.areaCode !== 'string') {
            return false;
        }
        if (typeof player.areaName !== 'string') {
            return false;
        }
        if (typeof player.season !== 'object') {
            return false;
        }
        if (typeof player.season.id !== 'number') {
            return false;
        }
        if (typeof player.season.name !== 'string') {
            return false;
        }
    }
    return true;
};
