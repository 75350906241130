import {
    createBrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
    Navigate,
    Outlet,
} from 'react-router-dom';
import Top from './routes/Top';
import CheckIn from './routes/CheckIn';
import { MESSAGES } from './constants/messages';
import Nft from './routes/Nft';
import SUGOROKU from './routes/MyPageSugoroku';
import NftDetail from './routes/NftDetail';
import Notifications from './routes/Notifications';
import MyPageTop from './routes/MyPageTop';
import MyPageLayout from './MyPageLayout';
import OpenIDCallback from './routes/OpenIdCallback';
import * as Sentry from '@sentry/react';
import React from 'react';

import { nftLoader } from './loaders/nftLoader';
import { accountInfoLoader } from './loaders/accountLoader';
import { nftDetailLoader } from './loaders/NftDetailLoader';
import { notificationDetailLoader } from './loaders/notificationDetailLoader';
import { notificationLoader } from './loaders/notificationLoader';
import WithAuthenticationWrapper from './WithAuthenticationWrapper';
import ErrorPage from './routes/ErrorPage';
import ConnectToWeb3Auth from './routes/ConnectToWeb3Auth';
import NotificationDetail from './routes/NotificationDetail';
import AccountInfo from './routes/AccountInfo';
import LoggedOut from './routes/LoggedOut';
import { PATH_MAPPING } from './constants/pathMapping';
import { Terms } from './routes/Terms';
import SugorokuNftDetail from './routes/SugorokuNftDetail';
import SugorokuRewardList from './routes/SugorokuRewardList';

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration({
                blockAllMedia: true,
            }),
        ],
        replaysSessionSampleRate:
            Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) ??
            0.0,
        replaysOnErrorSampleRate:
            Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) ??
            0.0,
        tracesSampleRate:
            Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) ?? 0.0,
        profilesSampleRate:
            Number(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE) ?? 0.0,
        environment: import.meta.env.MODE,
    });
}

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    {
        path: PATH_MAPPING.ROOT,
        element: <Outlet />,
        errorElement: <Navigate to={PATH_MAPPING.ERROR} />,
        children: [
            {
                index: true,
                element: <Top />,
            },
            {
                path: PATH_MAPPING.TERMS,
                element: <Terms />,
            },
            {
                path: PATH_MAPPING.ERROR,
                element: <ErrorPage />,
            },
        ],
    },
    {
        path: PATH_MAPPING.MY_PAGE,
        element: <WithAuthenticationWrapper component={MyPageLayout} />,
        errorElement: (
            <Navigate
                to={PATH_MAPPING.ROOT}
                state={{ message: MESSAGES.API_ERROR_DIALOG_MESSAGE }}
            />
        ),
        children: [
            {
                index: true,
                element: <WithAuthenticationWrapper component={MyPageTop} />,
                loader: () => {
                    return accountInfoLoader(false);
                },
            },
            {
                path: PATH_MAPPING.ACCOUNT_INFO,
                element: <WithAuthenticationWrapper component={AccountInfo} />,
                loader: () => {
                    return accountInfoLoader(true);
                },
            },
            {
                path: PATH_MAPPING.CHECK_IN,
                element: <WithAuthenticationWrapper component={CheckIn} />,
            },
            {
                path: PATH_MAPPING.NFT,
                element: <WithAuthenticationWrapper component={Nft} />,
                loader: nftLoader,
            },
            {
                path: PATH_MAPPING.SUGOROKU,
                element: <WithAuthenticationWrapper component={SUGOROKU} />,
            },
            {
                path: PATH_MAPPING.SUGOROKU_REWARDS,
                element: (
                    <WithAuthenticationWrapper component={SugorokuRewardList} />
                ),
            },
            {
                path: PATH_MAPPING.SUGOROKU_NFT_DETAILS,
                element: (
                    <WithAuthenticationWrapper component={SugorokuNftDetail} />
                ),
                loader: nftDetailLoader,
            },
            {
                path: PATH_MAPPING.NFT_DETAILS,
                element: <WithAuthenticationWrapper component={NftDetail} />,
                loader: nftDetailLoader,
            },
            {
                path: PATH_MAPPING.NOTIFICATIONS,
                element: (
                    <WithAuthenticationWrapper component={Notifications} />
                ),
                loader: notificationLoader,
            },
            {
                path: PATH_MAPPING.NOTIFICATION_DETAIL,
                element: (
                    <WithAuthenticationWrapper component={NotificationDetail} />
                ),
                loader: notificationDetailLoader,
            },
        ],
    },
    {
        path: PATH_MAPPING.OPENID_CALLBACK,
        element: <OpenIDCallback />,
        errorElement: (
            <Navigate
                to={PATH_MAPPING.ROOT}
                state={{ message: MESSAGES.API_ERROR_DIALOG_MESSAGE }}
            />
        ),
    },
    {
        path: PATH_MAPPING.CONNECT_TO_WEB3AUTH,
        element: <ConnectToWeb3Auth />,
    },
    {
        path: PATH_MAPPING.LOGGED_OUT,
        element: <LoggedOut />,
        errorElement: (
            <Navigate
                to={PATH_MAPPING.ROOT}
                state={{ message: MESSAGES.API_ERROR_DIALOG_MESSAGE }}
            />
        ),
    },
]);

export default router;
