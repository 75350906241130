import React from 'react';
import styled from 'styled-components';
import DateFormatter from '../lib/dateFormatter';
import { EventName } from './EventName';
import noimage from '../assets/images/noimage.jpg';
import Nft from '../types/nftType';
import SugorokuEventLink from './SugorokuEventLink';

const NFTContainer = styled.div`
    width: 157px;
    height: 230px;
`;

const StyledImage = styled.img`
    height: 230px;
    box-shadow: 3px 3px 6px rgba(0 0 0/60%);
`;

const DateAndIconsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

const DateText = styled.span`
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #848487;
`;

const ContentText = styled.span`
    display: block;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
`;

type NFTCardProps = {
    nft: Nft;
    isFlipped: boolean;
};

export const SugorokuNFTCard: React.FC<NFTCardProps> = ({ nft }) => {
    return (
        <SugorokuEventLink eventId={nft.event.id}>
            <NFTContainer>
                <StyledImage
                    src={nft.imageUrl || noimage}
                    alt={`NFT image for ${nft.event.name}`}
                />
            </NFTContainer>
        </SugorokuEventLink>
    );
};

export const SugorokuNFTCardInfo: React.FC<{ nft: Nft }> = ({ nft }) => {
    return (
        <SugorokuEventLink eventId={nft.event.id}>
            <>
                <DateAndIconsContainer>
                    <DateText>
                        {DateFormatter.formatDate(nft.event.date)}
                    </DateText>
                </DateAndIconsContainer>
                <ContentText>
                    <EventName name={nft.event.name} />
                </ContentText>
            </>
        </SugorokuEventLink>
    );
};
